import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { CollapsibleConfig, CollapsibleToastComponent } from '../elements/collapsible-toast.component';

export enum ToastrType {
  Error = 0,
  Warning,
  Info,
  Success,
}

@Injectable()

export class NotificationService {
  private stagedErrorMessages: NotificationMessage[] = [];
  private stagedWarningMessages: NotificationMessage[] = [];
  private stagedInfoMessages: NotificationMessage[] = [];
  private stagedSuccessMessages: NotificationMessage[] = [];

  private isErrorTimerActive: boolean = false;
  private isWarningTimerActive: boolean = false;
  private isInfoTimerActive: boolean = false;
  private isSuccessTimerActive: boolean = false;

  private timeoutDuration: number = 0;

  constructor(private toastr: ToastrService) {}

  /**
   * Creates a fully-configured error toast with instructions on how to go about requesting permissions.
   */
  permissionError(): void {
    this.error(
      'You do not have permission to complete the request. Visit <a href="https://studio.343i.xboxgamestudios.com/display/CD/Access+to+APIs%2c+Tools%2c+and+Admin+Sites" class="error-link" target="_blank">this page</a> for more information & access request links.',
      'Insufficient Permissions',
      { disableTimeOut: true, enableHtml: true, tapToDismiss: false, closeButton: true, toastClass: 'ngx-toastr permission-error-toastr inherits-display' },
    );
  }

  /**
   * Stages a new error toast with the params provided & then creates a new 2-second timer to aggregate all error toasts within that timeframe. If a 2-second timer is already running, then a duplicate timer is not created
   * @param message the "content" of the toast object; the message that's used to provide more context to the issue
   * @param title the headline of the toast object, such as "An Error Occured", or "Insufficient Permissions"
   * @param options the extra options to enable for the toast object. These only apply if the toast does not become part of a "collapsible" toast
   */
  error(message?: string, title?: string, options?: Partial<IndividualConfig>): void {
    this.stageToast(ToastrType.Error, message, title, options);
    if (this.isErrorTimerActive) {
      return;
    }
    this.isErrorTimerActive = true;
    setTimeout(() => {
      this.displayErrorToastr();
      this.isErrorTimerActive = false;
      this.stagedErrorMessages = [];
    }, this.timeoutDuration);
  }

  /**
   * Stages a new warning toast with the params provided & then creates a new 2-second timer to aggregate all warning toasts within that timeframe. If a 2-second timer is already running, then a duplicate timer is not created
   * @param message the "content" of the toast object; the message that's used to provide more context to the issue
   * @param title the headline of the toast object
   * @param options the extra options to enable for the toast object. These only apply if the toast does not become part of a "collapsible" toast
   */
  warning(message?: string, title?: string, options?: Partial<IndividualConfig>): void {
    this.stageToast(ToastrType.Warning, message, title, options);
    if (this.isWarningTimerActive) {
      return;
    }
    this.isWarningTimerActive = true;
    setTimeout(() => {
      this.displayWarningToastr();
      this.isWarningTimerActive = false;
      this.stagedWarningMessages = [];
    }, this.timeoutDuration);
  }

  /**
   * Stages a new information toast with the params provided & then creates a new 2-second timer to aggregate all information toasts within that timeframe. If a 2-second timer is already running, then a duplicate timer is not created
   * @param message the "content" of the toast object; the message that's used to provide more context to the message
   * @param title the headline of the toast object
   * @param options the extra options to enable for the toast object. These only apply if the toast does not become part of a "collapsible" toast
   */
  info(message?: string, title?: string, options?: Partial<IndividualConfig>): void {
    this.stageToast(ToastrType.Info, message, title, options);
    if (this.isInfoTimerActive) {
      return;
    }
    this.isInfoTimerActive = true;
    setTimeout(() => {
      this.displayInfoToastr();
      this.isInfoTimerActive = false;
      this.stagedInfoMessages = [];
    }, this.timeoutDuration);
  }

  /**
   * Stages a new success toast with the params provided & then creates a new 2-second timer to aggregate all success toasts within that timeframe. If a 2-second timer is already running, then a duplicate timer is not created
   * @param message the "content" of the toast object; the message that's used to provide more context to the message
   * @param title the headline of the toast object, such as "Save Successfull"
   * @param options the extra options to enable for the toast object. These only apply if the toast does not become part of a "collapsible" toast
   */
  success(message?: string, title?: string, options?: Partial<IndividualConfig>): void {
    this.stageToast(ToastrType.Success, message, title, options);
    if (this.isSuccessTimerActive) {
      return;
    }
    this.isSuccessTimerActive = true;
    setTimeout(() => {
      this.displaySuccessToastr();
      this.isSuccessTimerActive = false;
      this.stagedSuccessMessages = [];
    }, this.timeoutDuration);
  }

  /* doesn't collapse messages together */
  simpleSuccess(message?: string, title?: string, options?: Partial<IndividualConfig>) {
    this.toastr.success(message, title, options);
  }

  /**
   * uses the toastr service to create/display a new Error Toastr object. If only one error is staged, then it will be displayed as a standalone toast (using the title & individual config options provided). Otherwise, all staged errors will be displayed in a single "collapsible" toast.
   */
  private async displayErrorToastr(): Promise<void> {
    if (this.stagedErrorMessages.length === 1) {
      this.toastr.error(this.stagedErrorMessages[0].message, this.stagedErrorMessages[0].title, this.stagedErrorMessages[0].options);
    } else {
      const title = 'Multiple errors occured';
      const options: Partial<CollapsibleConfig> = {
        disableTimeOut: true,
        closeButton: true,
        enableHtml: true,
        toastClass: 'ngx-toastr toast-error collapsible-toastr inherits-display',
        tapToDismiss: true,
        extraMessages: [...this.stagedErrorMessages.slice(1).map(msg => msg.message)],
        toastComponent: CollapsibleToastComponent,
      };
      this.toastr.show(this.stagedErrorMessages[0].message, title, options);
    }
  }

  /**
   * uses the toastr service to create/display a new Warning Toastr object. If only one warning is staged, then it will be displayed as a standalone toast (using the title & individual config options provided). Otherwise, all staged warning will be displayed in a single "collapsible" toast.
   */
  private displayWarningToastr(): void {
    if (this.stagedWarningMessages.length === 1) {
      this.toastr.warning(this.stagedWarningMessages[0].message, this.stagedWarningMessages[0].title, this.stagedWarningMessages[0].options);
    } else {
      const title = 'Multiple warnings occured';
      const options: Partial<CollapsibleConfig> = {
        disableTimeOut: true,
        closeButton: true,
        enableHtml: true,
        toastClass: 'ngx-toastr toast-warning collapsible-toastr inherits-display',
        tapToDismiss: false,
        extraMessages: [...this.stagedWarningMessages.slice(1).map(msg => msg.message)],
        toastComponent: CollapsibleToastComponent,
      };
      this.toastr.show(this.stagedWarningMessages[0].message, title, options);
    }
  }

  /**
   * uses the toastr service to create/display a new Informational Toastr object. If only one info message is staged, then it will be displayed as a standalone toast (using the title & individual config options provided). Otherwise, all staged info messages will be displayed in a single "collapsible" toast.
   */
  private displayInfoToastr(): void {
    if (this.stagedInfoMessages.length === 1) {
      this.toastr.info(this.stagedInfoMessages[0].message, this.stagedInfoMessages[0].title, this.stagedInfoMessages[0].options);
    } else {
      const title = 'Multiple infos occured';
      const options: Partial<CollapsibleConfig> = {
        disableTimeOut: true,
        closeButton: true,
        enableHtml: true,
        toastClass: 'ngx-toastr toast-info collapsible-toastr inherits-display',
        tapToDismiss: false,
        extraMessages: [...this.stagedInfoMessages.slice(1).map(msg => msg.message)],
        toastComponent: CollapsibleToastComponent,
      };
      this.toastr.show(this.stagedInfoMessages[0].message, title, options);
    }
  }

  /**
   * uses the toastr service to create/display a new success Toastr object. If only one success message is staged, then it will be displayed as a standalone toast (using the title & individual config options provided). Otherwise, all staged success messages will be displayed in a single "collapsible" toast.
   */
  private displaySuccessToastr(): void {
    if (this.stagedSuccessMessages.length === 1) {
      this.toastr.success(this.stagedSuccessMessages[0].message, this.stagedSuccessMessages[0].title, this.stagedSuccessMessages[0].options);
    } else {
      const title = 'Multiple successes occured';
      const options: Partial<CollapsibleConfig> = {
        disableTimeOut: true,
        closeButton: true,
        enableHtml: true,
        toastClass: 'ngx-toastr toast-success collapsible-toastr inherits-display',
        tapToDismiss: false,
        extraMessages: [...this.stagedSuccessMessages.slice(1).map(msg => msg.message)],
        toastComponent: CollapsibleToastComponent,
      };
      this.toastr.show(this.stagedSuccessMessages[0].message, title, options);
    }
  }

  /**
   * stages the data to form a new toast inside a collection for toasts of the same type. This method will not stage anything if the "new" toast's message can be found in one of the currently staged toasts
   * @param type - used to determine what type of toast object should be staged (ie Error, Warning, Info or Success)
   * @param message - the message of the new toast. this is used to determine "uniqueness" against other toasts
   * @param title - the title that should display on the toast (if the toast does not become part of a collapsible toast)
   * @param options - custom config settings for the toast. these values are overriden if/when the toast becomes part of a collapsible toast
   */
  private stageToast(type: ToastrType, message?: string, title?: string, options?: Partial<IndividualConfig>): void {
    if (type === ToastrType.Error && !this.stagedErrorMessages.some(toast => toast.message === message)) {
      this.stagedErrorMessages.push({ message, title, options });
    } else if (type === ToastrType.Warning && !this.stagedWarningMessages.some(toast => toast.message === message)) {
      this.stagedWarningMessages.push({ message, title, options });
    } else if (type === ToastrType.Info && !this.stagedInfoMessages.some(toast => toast.message === message)) {
      this.stagedInfoMessages.push({ message, title, options });
    } else if (type === ToastrType.Success && !this.stagedSuccessMessages.some(toast => toast.message === message)) {
      this.stagedSuccessMessages.push({ message, title, options });
    }
  }
}

export interface NotificationMessage {
  message?: string;
  title?: string;
  options?: Partial<IndividualConfig>;
}
