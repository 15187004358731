/// ////////////////////////////////////////////////////////////////////////////////////////////
/// DO NOT CHANGE THIS FILE                                             ///////////////////////
/// ////////////////////////////////////////////////////////////////////////////////////////////
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { BroadcastService, MsalService } from '@azure/msal-angular';
import { SpartanToken } from '../models/spartan-token';
import { NotificationService } from '../guarded/services/notification.service';
// import { ApplicationInsightsService } from "./applicationInsights.service";
import { TENANTS } from '../tenants.constants';

@Injectable()
export class AuthService {
  public static readonly msftTenant: string = '72f988bf-86f1-41af-91ab-2d7cd011db47';
  public static readonly studioDevTenant: string = '27d5a614-5e32-4ce9-9207-b3a63945b5bb';
  public static readonly clientId = 'abb09453-882a-46b8-b0b1-e9bac4fe58c0';
  public static readonly prodTenant: string = '2094aafd-3045-4703-a6f1-ad112ff1babe';
  public static readonly homeTenant: string = 'a9063893-a7c6-46ba-9e91-82f09ea18d17';

  static count = 1;

  aadTenantId = AuthService.studioDevTenant;
  aadInstance = 'https://login.microsoftonline.com/';
  readonly TENANTS = TENANTS;

  private tokenCurrentAudience = '';
  private spartanTokenHost = '';
  protected spartanToken: SpartanToken;

  constructor(private http: HttpClient, public notifier: NotificationService, private broadcastService: BroadcastService, private authService: MsalService) {
    this.aadTenantId = localStorage.getItem('tenant-id') || AuthService.studioDevTenant;
  }

  get Alias(): string {
    const user = this.authService.getAccount();
    const name: string = user.userName;
    return name.substr(0, name.indexOf('@'));
  }

  get Id(): string {
    const user = this.authService.getAccount();
    const oid = `oid(${user.idToken.oid})`;
    return oid;
  }

  /** Fetches the canonical token of the user. */
  get CanonicalToken(): string {
    const user = this.authService.getAccount();
    return (AuthService.msftTenant === user.idToken.tid) ? `auid(${user.idToken.oid})` : `atui(${user.idToken.tid}.${user.idToken.oid})`;
  }

  /** Checks if user is logged in */
  check() {
    return !!this.authService.getAccount();
  }

  /** Fetches the currently logged in user. */
  getUser(): any {
    return this.authService.getAccount();
  }
  /**
   * Updates the stored Tenant ID
   * @param newTenentId - New Tenant ID
   */
  changeTenantId(newTenentId: string, newAadInstance?: string): void {
    this.aadTenantId = newTenentId;
    this.aadInstance = newAadInstance || this.aadInstance;
    localStorage.setItem('tenant-id', this.aadTenantId);
    localStorage.setItem('nextTenant', this.aadTenantId);
    if (this.TENANTS.find(x => x.id === newTenentId).defaultEnv.toLowerCase() === 'prod') {
      localStorage.setItem('lastTitle', 'hi');
    } else {
      localStorage.setItem('lastTitle', 'hi343');
    }
  }

  /** Fetches the spartan token. */
  async getSpartanToken(
    isTestEnv: boolean = true,
    tokenAudience: string = 'https://settings.test.svc.halowaypoint.com/spartan-token',
    spartanTokenHost: string = 'https://settings-intone.test.svc.halowaypoint.com',
    environment: string = 'test',
  ): Promise<SpartanToken> {
    if (!this.spartanToken
      || moment().isSameOrAfter(this.spartanToken.ExpiresUtc.ISO8601Date)
      || this.tokenCurrentAudience !== tokenAudience
      || this.spartanTokenHost !== spartanTokenHost) {
      this.tokenCurrentAudience = tokenAudience;
      this.spartanTokenHost = spartanTokenHost;
      console.debug('AUTH: ST null or expired');

      try {
        const token = await this.acquireToken(tokenAudience);
        const reqData = {
          Audience: 'urn:343:s3:services',
          MinVersion: 4,
          Proof: [{
            TokenType: 'AAD_v1Token',
            Token: token,
          }],
        };

        const response = await this.http.post(`${spartanTokenHost}/spartan-token`, reqData).toPromise();
        this.spartanToken = response as SpartanToken;
      } catch (e) {
        console.debug('in error', e.message);
        this.renewToken(tokenAudience);
        console.error(e);
        throw (e);
      }
    }

    const date = new Date();
    date.setTime(date.getTime() + (10 * 60 * 1000)); // 10m cookie
    document.cookie = `x-343-st=${this.spartanToken.SpartanToken},${isTestEnv},${environment};expires=${date.toUTCString()};path=/`;
    return this.spartanToken;
  }

  /** Acquires an AAD token. */
  async acquireToken(audience: string): Promise<string> {
    const tenantId = localStorage.getItem('tenant-id') || AuthService.studioDevTenant;
    if (tenantId === AuthService.prodTenant) {
      audience = 'https://settings.svc.halowaypoint.com/spartan-token';
    }
    console.debug(`in acquireToken() tenant is--> ${tenantId}`);
    let tokenRequest: any;
    // eslint-disable-next-line prefer-const
    tokenRequest = {
      scopes: [
        `${audience}/user_impersonation`,
      ],
      authority: `https://login.microsoftonline.com/${tenantId}/`,
      audience,
      prompt: 'none',
    };
    if (tenantId === AuthService.prodTenant) {
      tokenRequest.authority = `https://login.microsoftonline.com/${tenantId}/`;
    }
    let accessToken: string | PromiseLike<string>;
    await this.authService.acquireTokenSilent(tokenRequest)
      .then(response => {
        accessToken = response.accessToken;
      })
      .catch((err: { name: string; }) => {
        if (err.name === 'InteractionRequiredAuthError') {
          return this.authService.acquireTokenPopup(tokenRequest)
            .then(response => {
              console.debug('response -> aquireTokenPopup', response);
              accessToken = response.accessToken;
            })
            .catch(error => {
              console.debug('error -> aquireTokenPopup', error);
              throw (error);
            });
        }
        return null;
      });
    return accessToken;
  }

  /** Executes the login flow. */
  async login() {
    this.authService.loginRedirect();
  }

  /** Executes the logout flow. */
  async logOut() {
    this.authService.logout();
  }

  /** Handles the AAD return. */
  handleReturn() {
    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }
      console.debug('Redirect Success: ', response);
      localStorage.setItem('requestId', response.uniqueId);
    });
  }
  private renewToken(url: string) {
    this.notifier.warning('Auto renewing aad token', 'renewToken', { positionClass: 'toast-bottom-center' });
    this.acquireToken(url).then(scope => this.authService.clearCacheForScope(scope));
  }
}
