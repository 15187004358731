/// /////////////////////////////////////////////
/// ATTENTION:                               ///
/// Add your files to guarded.module.ts      ///
/// This file should only contain the base   ///
///    site that will be delivered without   ///
///    user authentication.                  ///
/// /////////////////////////////////////////////
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MSAL_CONFIG, MSAL_CONFIG_ANGULAR, MsalAngularConfiguration, MsalGuard, MsalInterceptor, MsalModule, MsalService } from '@azure/msal-angular';
import { Configuration } from 'msal';
import { MatDialogModule } from '@angular/material/dialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthService } from './services/auth.service';
import { LoginTenantSelectionComponent } from './components/login/login-tenant-selection.component';
import { CollapsibleToastComponent } from './guarded/elements/collapsible-toast.component';
import { NotificationService } from './guarded/services/notification.service';
import { ResetLoginComponent } from './components/reset-login/reset-login.component';
import { ApplicationInsightsService } from './services/applicationinsights.service';

export const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const protectedResourceMap: [string, string[]][] = [];

export const AppRegistrationClientId : string = 'abb09453-882a-46b8-b0b1-e9bac4fe58c0'; //Halo Admin Sites on s3resources aka homeTenant
export const StudioDevTenantId : string = '27d5a614-5e32-4ce9-9207-b3a63945b5bb';

export function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    popUp: !isIE,
    consentScopes: [],
    protectedResourceMap,
    extraQueryParameters: {},
  };
}
export function MSALConfigFactory(): Configuration {
  const tenantId = localStorage.getItem('tenant-id') || StudioDevTenantId;
  return {
    auth: {
      clientId: AppRegistrationClientId,
      authority: `https://login.microsoftonline.com/${tenantId}/`,
      validateAuthority: false,
      redirectUri: window ? window.location.origin : null,
      postLogoutRedirectUri: window ? `${window.location.origin}/reset-login` : null,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE,
    },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    LoginTenantSelectionComponent,
    CollapsibleToastComponent,
    ResetLoginComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'player' }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ToastrModule.forRoot(),
    MsalModule,
    MatDialogModule,
  ],
  providers: [AuthService, ApplicationInsightsService, NotificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory,
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory,
    },
    MsalService,
    MsalGuard,
  ],
  bootstrap: [AppComponent],
  entryComponents: [CollapsibleToastComponent],
})
export class AppModule { }
