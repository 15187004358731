/// /////////////////////////////////////////////
/// DO NOT CHANGE THIS FILE.      //////////////
/// /////////////////////////////////////////////
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BroadcastService } from '@azure/msal-angular';
import { TENANTS } from '../../tenants.constants';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'login',
  templateUrl: './login.template.html',
})
export class LoginComponent implements OnInit {
  public isLoggedIn: boolean;
  public showTenants: boolean = false;
  public spartanToken: string;
  public canRetry: boolean = false;
  public subscription: any;

  public loginError: any = null;

  public state: any = {
    signInError: false,
  };

  constructor(private auth: AuthService, private router: Router, private broadcastService: BroadcastService) { }

  public ngOnInit() {
    this.subscription = this.broadcastService.subscribe('msal:loginSuccess',
      (payload: any): void => {
        console.debug(`login success ${JSON.stringify(payload)}`);
        this.isLoggedIn = true;
      });
    console.debug('login: on init');
    this.auth.handleReturn(); // handles return if any

    console.debug(`login: is logged in?${this.isLoggedIn}`);

    this.isLoggedIn = this.auth.check();

    if (this.isLoggedIn) {
      console.debug('login: fetching ST');
      const currentTenant = TENANTS.filter(t => t.id === this.auth.aadTenantId)[0];
      let environment = localStorage.getItem('lastEnv');
      let title = localStorage.getItem('lastTitle') || 'hi343';
      if (currentTenant.defaultEnv === 'Prod') {
        title = localStorage.getItem('lastTitle') || 'hi';
      }
      const lastTenantId = localStorage.getItem('tenant-id');
      if (!lastTenantId || lastTenantId !== currentTenant.id || !environment) {
        environment = currentTenant.defaultEnv;
      }
      const stv4Host = `https://settings${currentTenant.stv4Affix}.svc.halowaypoint.com`;
      const stv4Aud = environment.toLocaleLowerCase() === 'prod' ? 'https://settings.svc.halowaypoint.com/spartan-token' : 'https://settings.test.svc.halowaypoint.com/spartan-token';
      const isTestEnv = !environment.toLowerCase().startsWith('prod');
      this.auth.getSpartanToken(isTestEnv, stv4Aud, stv4Host, environment.toLowerCase()).then(token => {
        if (token.SpartanToken) {
          console.debug(`spartan token ${token.SpartanToken}`);
          this.spartanToken = token.SpartanToken;
        }
        this.router.navigate([localStorage.getItem('post-login-path') || `/guard/${environment}/${title}/home`]);
      }).catch(e => {
        console.error(e);
        this.state.signInError = true;
        this.loginError = e;
      });
    } else {
      const requestedTenant = localStorage.getItem('nextTenant');
      if (requestedTenant) {
        localStorage.removeItem('nextTenant');
        this.loginStart();
      } else {
        this.showTenants = true;
      }
      this.showTenants = !localStorage.getItem('tenant-id');
    }

    setTimeout(() => {
      this.canRetry = true;
    }, 1000);
  }

  private loginStart(): void {
    this.auth.login();
  }

  public logout(): void {
    localStorage.clear();
    window.location.replace(`${window.location.protocol}//${window.location.host}`);
  }

  public ngOnDestroy() {
    // disconnect from broadcast service on component destroy
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
